import React, { useState } from 'react';
import { Button, Container, Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { observer } from "mobx-react-lite";
import { $authHost } from '../http';
import { message } from 'antd';
import NavBar from '../components/NavBar';

const Test = observer(() => {
  const [url, setURL] = useState('')
  const [body, setBody] = useState('')
  const [type, setType] = useState('POST')
  const [answer, setAnswer] = useState('POST')

  const send = async (event) => {
    try {
      event.preventDefault();
      let data
      switch (type) {
        case 'POST': data = await $authHost.post(url, { body })
          break
        case 'GET': data = await $authHost.get(url)
          break
        case 'PUT': data = await $authHost.put(url, { body })
          break
        case 'DELETE': data = await $authHost.delete(url, { body })
          break
      }
      console.log(data);
      setAnswer(JSON.stringify(data))
      //alert(data.data.toString());

    } catch (e) {
      alert(e)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      console.log('enter press here! ')
      send(event)
    }
  }

  return (
    <>
      
      <Form className="d-flex flex-column">
        <Form.Select onChange={(e) => setType(e.target.value)} className="mt-3 bg-transparent" bsPrefix="form-select" defaultValue={type}>
          <option key={'POST'} value={'POST'}>{'POST'}</option>
          <option key={'GET'} value={'GET'}>{'GET'}</option>
          <option key={'PUT'} value={'PUT'}>{'PUT'}</option>
          <option key={'DELETE'} value={'DELETE'}>{'DELETE'}</option>
        </Form.Select>
        <Form.Control
          className="mt-3"
          placeholder="URL:"
          value={url}
          onChange={e => setURL(e.target.value)}
        />
        <Form.Control as="textarea"
          className="mt-3"
          placeholder="BODY:"
          rows={5}
          value={body}
          onChange={e => setBody(e.target.value)}
        />
        <Button
          className='mt-3 align-self-end'
          variant={'outline-success'}
          onClick={send}
        >
          Отправить
        </Button>
        <Form.Control as="textarea"
          className="mt-3"
          placeholder="ANSWER:"
          rows={5}
          value={answer}
        />
      </Form>
    </>
  );
});

export default Test;
