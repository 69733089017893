import Modal from "react-bootstrap/Modal";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { createDescription, fetchDescriptions, modyfyDescription, oneRoute } from '../../../http/teacherApi';
import Editor from "../../utilites/quill/EditorBlur";
import { Typography } from 'antd';
import FilesUpload from "../../utilites/FilesUpload";

const { Paragraph } = Typography;

const DescriptionsTable = observer(({ show, onHide, routeID }) => {
  const [descriptions, setDescriptions] = useState([])
  const [route, setRoute] = useState({})

  /*   const [editorVisible, setEditorVisible] = useState(false)
    const [editorValue, setEditorValue] = useState(0)
    const [editorID, setEditorID] = useState(0) */

  useEffect(() => {
    loadData(routeID)
    oneRoute(routeID).then(data => setRoute(data))
  }, [routeID])

  const addDescription = async () => {
    const id = await createDescription(routeID)
    setDescriptions([...descriptions, { id: id }])
  }

  const loadData = async () => {
    const data = await fetchDescriptions(routeID)
    setDescriptions(data)
  }

  const changeIndex = (index, id) => {
    changeDescription(index, null, id)
    setDescriptions(descriptions.map(i => i.id === id ? { ...i, ['index']: index } : i))
  }

  function changeText(text, id) {
    changeDescription(null, text, id)
    setDescriptions(descriptions.map(i => i.id === id ? { ...i, ['text']: text } : i))
  }

  const changeDescription = async (index, text, id) => {
    const data = await modyfyDescription(id, index, text)
  }

  /*   const clickEditor = (value, id) => {
      setEditorID(id)
      setEditorValue(value)
      setEditorVisible(true)
    } */

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Описания для маршрута #{route.index} ({route.name})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Описание</th>
              <th><Button onClick={() => addDescription()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
            </tr>
          </thead>
          <tbody>
            {descriptions.map(description =>
              <tr key={description.id}>
                <td>
                  {<Paragraph
                    editable={{
                      tooltip: 'Номер',
                      onChange: (value) => changeIndex(value, description.id),
                      triggerType: ['icon', 'text'],
                    }}
                  >
                    {description.index}
                  </Paragraph>}
                  {/*         <FormControl
                    placeholder='Номер'
                    value={description.index}
                    onChange={(e) => changeIndex(e.target.value, description.id)}
                  /> */}
                </td>
                <td>
                  {/*                   <RichTextEditor 
                    value={description.text}
                    dataToSave={description.id}
                    funcToSave={changeText}
                    placeholder="Введите ваш текст"
                  ></RichTextEditor> */}
                  {/*                   <Editor></Editor> */}
                  {/*                   <LinkEditor
                    placeholder="Введите ваш текст"
                  ></LinkEditor> */}
                  {/*                   <MediaEditor
                    placeholder="Введите ваш текст"
                  ></MediaEditor> */}
                  {/*                   <FormControl
                    placeholder='Введите описание'
                    value={description.text}
                    onChange={(e) => setDescriptions(descriptions.map(i => i.id === description.id ? { ...i, ['text']: e.target.value } : i))}
                    onBlur={(e) => changeText(e.target.value, description.id)}
                    type="text"
                  /> */}

                  <Editor
                    data={description.text}
                    /* idToSave={description.id} */
                    funcToSave={(val) => changeText(val, description.id)}
                    placeholder='Введите описание'
                  ></Editor>
                  <FilesUpload
                    fileList={description.fileList}
                    action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'descriptions'}&id=${description.id}`}
                  >
                  </FilesUpload>
                  {/*   { <HTMLViewer
                    value={convertToHTML(convertFromRaw(JSON.parse(description.text)))}
                    value={description.text}
                  ></HTMLViewer>} */}
                  {/* <Button onClick={setEditorVisible(true)}>Edit</Button>  */}
                  {/*  <Button onClick={clickEditor(description.text, description.id)}>Edit</Button>  */}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
      </Modal.Footer>
      {/* <QEditor show={editorVisible} onHide={() => setEditorVisible(false)} data={editorValue} idToSave={editorID} saveData={changeText} />  */}
    </Modal>
  );
});

export default DescriptionsTable;