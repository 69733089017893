import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Button } from 'react-bootstrap';
import { fetchTeacherScores, modyfyTeacherScores } from '../../../http/teacherApi';
import { Context } from '../../..';
import { createResponse } from '../../../http/studentApi';
import HTMLViewer from '../../utilites/HTMLViewer';
import { FaPlus } from 'react-icons/fa';
import Editor from '../../utilites/quill/EditorBlur';
import { InputNumber/* , Button, message, Upload */ } from 'antd';
import FilesUpload from '../../utilites/FilesUpload';
/* import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { test } from '../../../http/userApi'; */


const TeacherSetScore = observer(() => {
    const { teacher } = useContext(Context)
    const { user } = useContext(Context)

    const [fileList, setFileList] = useState([]); //TODO Изменить под АПИ

    useEffect(() => {
        loadData()
    }, [teacher.setSelectedSubMenu, teacher.selectedSideMenu])

    /*     useEffect(() => {
            loadDescriptions()
        }, [teacher.selectedModule, teacher.selectedTheme]) */

    /*     const acceptFileTypes = ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'application/doc', 'application/docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    
        const handleChange = (info) => {
            let newFileList = [...info.fileList];
    
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            newFileList = newFileList.slice(-10);
    
            // 2. Read from response and show file link
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                    file.uid = file.response.uid;
                }
                return file;
            });
            setFileList(newFileList);
        };
    
        const propsUpload = {
            name: 'file',
            //defaultFileList: fileList,
            //  customRequest: customRequest,
           // action: `${process.env.REACT_APP_API_URL}/api/test/?upload=desc&id=5`,
            accept: 'image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            beforeUpload: file => {
                // console.log("FFF",file,acceptFileTypes,acceptFileTypes.includes(file.type));
                const isValid = acceptFileTypes.includes(file.type);
                if (!isValid) {
                    message.error(`${file.name} недопустимый файл`);
                }
                return isValid || Upload.LIST_IGNORE;
            },
            onChange: handleChange,
            onRemove(file) {
                test(file).then((e) => {
                    if (e === 'done') {
                        message.success(`${file.name} файл успешно удален!`);
                    } else {
                        message.error(`${file.name} удаление файла сорвалось.`);
                    }
                })
            },
            showUploadList: {
                showDownloadIcon: true,
                downloadIcon: 'Загрузка',
                showRemoveIcon: true,
            },
        }; */

    const loadData = async () => {
        const data = await fetchTeacherScores(teacher.selectedDescription.id, teacher.selectedStudent.id)
        teacher.setTeacherScores(data)
    }

    const addScore = async () => {
        const data = await createResponse(teacher.selectedDescription.id, teacher.selectedStudent.id)
        teacher.setTeacherScores([...teacher.teacherScores, data])
    }

    const setScore = async (scoreID, teacherScore) => {
        const data = await modyfyTeacherScores(scoreID, teacherScore)
        //  teacher.setTeacherScores(teacher.teacherScores.map(i => i.id === scoreID ? { ...i, ['teacherScore']: teacherScore } : i))
    }

    const changeAnswer = async (answer, scoreID) => {
        const data = await modyfyTeacherScores(scoreID, null, answer)
        teacher.setTeacherScores(teacher.teacherScores.map(i => i.id === scoreID ? { ...i, ['answer']: answer } : i))
    }

    return (
        <Container>
            <div style={{'marginBottom':'15px'}}>Ученик {teacher.selectedStudent.name}</div>
            <div>
                <HTMLViewer
                    value={teacher.selectedDescription.text}
                ></HTMLViewer>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Ответ ученика</th>
                        <th>Оценка</th>
                        <th><Button onClick={() => addScore()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {teacher.teacherScores.map(score =>
                        <tr key={score.id}>
                            <td key={"a" + score.id}>
                                {score.ownerID != user.user.id ?
                                    <Container>
                                        <HTMLViewer
                                            value={score.answer}
                                        ></HTMLViewer>
                                        <FilesUpload
                                            fileList={score.fileList}
                                            disabled={true}
                                          //  action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'responses'}&id=${score.id}`}
                                        >
                                        </FilesUpload>
                                    </Container>
                                    :
                                    <Container>
                                        <Editor
                                            data={score.answer}
                                            /* idToSave={score.id} */
                                            funcToSave={(val) => changeAnswer(val, score.id)}
                                            placeholder='Введите ответ ученика'
                                        ></Editor>
                                        <FilesUpload
                                            fileList={score.fileList}
                                            action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'responses'}&id=${score.id}`}
                                        >
                                        </FilesUpload>
                                        {/*       <Upload {...propsUpload}
                                            fileList={fileList}
                                            action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'responses'}&id=${score.id}`} 
                                        >
                                            <Button icon={<UploadOutlined />}>Загрузка файла...</Button>
                                        </Upload> */}
                                    </Container>
                                }
                            </td>
                            <td key={"ss" + score.id}>
                                <InputNumber
                                    min={0}
                                    max={100}
                                    placeholder='Оценка'
                                    value={score.teacherScore}
                                    onChange={val => teacher.setTeacherScores(teacher.teacherScores.map(i => i.id === score.id ? { ...i, ['teacherScore']: val } : i))}
                                    onBlur={e => setScore(score.id, e.target.value)}
                                /* onChange={(val) => setScore(score.id, val)}  */
                                /*   onChange={(e) => changeScore(e.target.value, resp.id)} */
                                /*  onBlur={(e) => changeScore(e.target.value, resp.id)} */
                                /*  Разделить изменения onChange и onBlur */
                                ></InputNumber>

                                {/*                                 <Form>
                                    <Form.Control className="mt-2 mb-2"
                                        placeholder='Оценка'
                                        value={score.teacherScore}
                                        onChange={e => teacher.setTeacherScores(teacher.teacherScores.map(i => i.id === score.id ? { ...i, ['teacherScore']: e.target.value } : i))}
                                        onBlur={e => setScore(score.id, e.target.value)}
                                    />
                                </Form> */}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default TeacherSetScore;