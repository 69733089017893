import React, { useContext, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Container, Form, Button } from 'react-bootstrap';
import { fetchAttendance, fetchAttendanceData, modyfyAttendance } from '../../../http/teacherApi';
import { DatePicker, Checkbox, TimePicker, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import AttendanceEditTable from './AttendanceEditTable';
import AttendanceEditShedulled from './AttendanceEditShedulled';
import AttendanceEditNotShedulled from './AttendanceEditNotShedulled';

const AttendanceEdit = observer(() => {
    const { teacher } = useContext(Context)

    const dateFormat = 'DD.MM.YYYY';
    const timeFormat = 'HH:mm';
    const dateFormatForDB = 'YYYY-MM-DD';

    const [date, setDate] = useState(moment(moment.now()))
    const [shedulled, setShedulled] = useState(true)

    const [classes, setClasses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [classID, setClassID] = useState(teacher.classes[0]?.id)
    const [subjectID, setSubjectID] = useState(0)
    const [attendanceID, setAttendanceID] = useState(0)

    const [begin, setBegin] = useState(moment(moment.now()).format(timeFormat))
    const [end, setEnd] = useState(moment(moment.now() + 2700000).format(timeFormat))
    const [intervalID, setIntervalID] = useState(0)

    const [lessonComment, setLessonComment] = useState('')

    const [attendance, setAttendance] = useState([])

    useEffect(() => {
        loadData()
    }, [date])

    const loadData = async () => {
        const data = await fetchAttendanceData(date.format(dateFormatForDB))
        setShedulled(data.sheduled)
        if (data.sheduled) {
            let clas
            if (data.classes.find(clas => clas.selected == true)) {
                clas = data.classes.find(clas => clas.selected == true)
            } else {
                clas = data.classes[0]
            }
            setClassID(clas.id)
            setClasses(data.classes)
            setSubjects(clas.subjects)
            let subj
            if (clas.subjects.find(subj => subj.selected === true)) {
                subj = clas.subjects.find(subj => subj.selected === true)
            } else {
                subj = clas.subjects[0]
            }
            setSubjectID(subj.subjectID)
            setAttendanceID(subj.attendanceID)
            setIntervalID(subj.intervalID)
            loadAttendanceData(0, subj.attendanceID)
            setBegin(subj.begin)
            setEnd(subj.end)
            setLessonComment(subj.lessonComment)
            /*             setClassID(data.classes.find(clas => clas.selected == true)?.id)
                        setClasses(data.classes)
                        setSubjects(data.classes.find(clas => clas.selected == true)?.subjects)
                        setSubjectID(data.classes.find(clas => clas.selected == true)?.subjects.find(subj => subj.selected === true).subjectID)
                        setAttendanceID(data.classes.find(clas => clas.selected == true)?.subjects.find(subj => subj.selected === true).attendanceID)
                        setIntervalID(data.classes.find(clas => clas.selected == true)?.subjects.find(subj => subj.selected === true).intervalID)
                        loadAttendanceData(0, data.classes.find(clas => clas.selected == true)?.subjects.find(subj => subj.selected === true).attendanceID) */
        } else {
            setClassID(teacher.classes[0]?.id)
            setSubjectID(teacher.subjects[0]?.id)
            setBegin(data.begin)
            setEnd(data.end)
            loadAttendanceData(teacher.classes[0]?.id, 0)
        }
    }

    /*     useEffect(() => {
            //   console.log("LD",data);
            loadAttendanceData(classID, attendanceID)
        }, [classID, subjectID]) */

    const loadAttendanceData = async (classID, attendanceID) => {
        const data = await fetchAttendance(classID, attendanceID)
        setAttendance(data)
        return data
    }

    const onChangeDate = (date, dateString) => {
        setDate(date)
        //  console.log(date, dateString);
    };

    const setShedulledSubjectID = (attendanceID) => {
        //console.log("ATA",attendanceID,subjects,subjects.find(subject => subject.attendanceID == attendanceID));

        let subject = subjects.find(subject => subject.attendanceID == attendanceID)
        setIntervalID(subject.intervalID)
        setBegin(subject.begin)
        setEnd(subject.end)
        setLessonComment(subject.lessonComment)
        setAttendanceID(subject.attendanceID)
        setSubjectID(subject.subjectID)
        loadAttendanceData(0, subject.attendanceID)
    };

    const setShedulledClass = (clasID) => {
        setClassID(clasID)
        //   console.log("sub", clasID, classes.find(clas => clas.id == clasID)?.subjects)
        let subj = classes.find(clas => clas.id == clasID)?.subjects
        setSubjects(subj)
        let subjID, attenID
        if (subj.find(subj => subj.selected === true)) {
            subjID = subj.find(subj => subj.selected === true).subjectID
            attenID = subj.find(subj => subj.selected === true).attendanceID
        } else {
            subjID = subj[0].subjectID
            attenID = subj[0].attendanceID
        }
        setIntervalID(subj.find(sub => sub.subjectID === subjID).intervalID)
        setBegin(subj.find(sub => sub.subjectID === subjID).begin)
        setEnd(subj.find(sub => sub.subjectID === subjID).end)
        setLessonComment(subj.lessonComment)
        setSubjectID(subjID)
        setAttendanceID(attenID)
        loadAttendanceData(0, attenID)
        /*      setSubjectID(classes.find(clas => clas.id == clasID)?.subjects.find(subj => subj.selected === true).subjectID)
             setAttendanceID(classes.find(clas => clas.id == clasID)?.subjects.find(subj => subj.selected === true).attendanceID)
             loadAttendanceData(0, classes.find(clas => clas.id == clasID)?.subjects.find(subj => subj.selected === true).attendanceID) */
    };

    const setNotShedulledClass = (clasID) => {
        setClassID(clasID)
        loadAttendanceData(clasID, 0)
    };

    const changeBegin = (begin) => {
        // console.log("beg",begin);
        setBegin(begin)
        //  changeAttendance(id, null, begin, null, null)
        setAttendance(attendance.map(i => { return { ...i, begin } }))
    }

    const changeEnd = (end) => {
        setEnd(end)
        //  changeAttendance(id, null, null, end, null)
        setAttendance(attendance.map(i => { return { ...i, end } }))
    }

    const onChangeShedulled = (e) => {
        //  console.log(`checked = ${e.target.checked}`);
        if (!e.target.checked) {
            setShedulled(true)
            loadData()
        } else {
            setShedulled(false)
            setClassID(teacher.classes[0]?.id)
            setSubjectID(teacher.subjects[0]?.id)
            loadAttendanceData(teacher.classes[0]?.id, 0).then(data => setAttendance(data.map(i => { return { ...i, begin, end } })))
        }
    };

    const onChangeForm = () => {
        try {
            const formData = new FormData()
            formData.append('date', date.toString())
            formData.append('classID', classID)
            if (shedulled) {
                formData.append('attendanceID', attendanceID)
            }
            formData.append('subjectID', subjectID)
            formData.append('shedulled', shedulled)
            formData.append('lessonComment', lessonComment)
            formData.append('begin', begin)
            formData.append('end', end)
            formData.append('intervalID', intervalID)
            formData.append('attendance', JSON.stringify(attendance))
            modyfyAttendance(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                    loadData()
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    };

    const componentDidCatch = (error, info) => {
        console.log("ERROR", error.message, info);
        alert(error.message)
    }

    return (
        <Container>
            <DatePicker onChange={onChangeDate} value={date} format={dateFormat} allowClear={false} />
            <Checkbox style={{'marginLeft':'15px'}} checked={!shedulled} onChange={onChangeShedulled}>Урок не по расписанию</Checkbox>

            {shedulled ?
                <div style={{'marginTop':'15px','marginBottom':'15px'}}>
                    <AttendanceEditShedulled
                        classes={classes}
                        subjects={subjects}
                        classID={classID}
                        setClassID={setShedulledClass}
                        subjectID={attendanceID}
                        setSubjectID={setShedulledSubjectID}
                        lessonComment={lessonComment}
                    />
                </div>
                :
                <div style={{'marginTop':'15px','marginBottom':'15px'}}>
                    <AttendanceEditNotShedulled
                        classID={classID}
                        setClassID={setNotShedulledClass}
                        subjectID={subjectID}
                        setSubjectID={setSubjectID}
                        lessonComment={lessonComment}
                        setLessonComment={setLessonComment}
                        begin={begin}
                        setBegin={changeBegin}
                        end={end}
                        setEnd={changeEnd}
                    />
                </div>
            }

            <AttendanceEditTable attendance={attendance} setAttendance={setAttendance} />
            <Button variant="outline-success" onClick={onChangeForm}>Сохранить</Button>
        </Container>
    )
});

export default AttendanceEdit;