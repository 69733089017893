import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Button, Container, FormControl } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { BiDotsHorizontal } from 'react-icons/bi';
import { createModule, fetchModules, modyfyModule } from '../../http/teacherApi';
import ThemesTable from './modals/ThemesTable';
import { Typography } from 'antd';

const { Paragraph } = Typography;

const ModulesList = observer((data) => {
    const [modules, setModules] = useState([])
    const [themesVisible, setThemesVisible] = useState(false)
    const [moduleIDToModal, setModuleIDToModal] = useState(0)

    useEffect(() => {
        loadData(data.classID, data.subjectID)
    }, [data])

    const addModule = async () => {
        const id = await createModule(data.classID, data.subjectID)
        setModules([...modules, { name: '', id: id }])
    }

    const loadData = async (classID, subjectID) => {
        const data = await fetchModules(classID, subjectID)
        setModules(data)
    }

    const changeIndex = (index, id) => {
        changeModule(index, null, null, null, id)
        setModules(modules.map(i => i.id === id ? { ...i, ['index']: index } : i))
    }

    const changeName = (name, id) => {
        changeModule(null, name, null, null, id)
        setModules(modules.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }

    const changeBegin = (begin, id) => {
        changeModule(null, null, begin, null, id)
        setModules(modules.map(i => i.id === id ? { ...i, ['begin']: begin } : i))
    }

    const changeEnd = (end, id) => {
        changeModule(null, null, null, end, id)
        setModules(modules.map(i => i.id === id ? { ...i, ['end']: end } : i))
    }

    const changeModule = async (index, name, begin, end, id) => {
        const data = await modyfyModule(id, index, name, begin, end)
    }

    const deleteModule = async (id) => {
       // const data = await deleteModule(id)
    }

    const openThemes = (id) => {
        setModuleIDToModal(id)
        setThemesVisible(true)
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Название</th>
                        <th>Начало</th>
                        <th>Конец</th>
                        <th><Button onClick={() => addModule()} className='float-end'>
                            <FaPlus className="d-flex justify-content-between" />
                        </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {modules.map(module =>
                        <tr key={module.id}>
                            <td>
                                {<Paragraph
                                    editable={{
                                        tooltip: 'Номер',
                                        onChange: (value) => changeIndex(value, module.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {module.index}
                                </Paragraph>}
                                {/*       <FormControl
                                    placeholder='Номер'
                                    value={module.index}
                                    onChange={(e) => changeIndex(e.target.value, module.id)}
                                /> */}
                            </td>
                            <td>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Введите название',
                                        onChange: (value) => changeName(value, module.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {module.name}
                                </Paragraph>
                                {/*   <FormControl
                                    placeholder='Введите название'
                                    value={module.name}
                                    onChange={(e) => changeName(e.target.value, module.id)}
                                /> */}
                            </td>
                            <td>
                                <FormControl
                                    placeholder='Введите начало'
                                    value={module?.begin ? module.begin.split("T")[0] : ''}
                                    onChange={(e) => changeBegin(e.target.value, module.id)}
                                    type='date'
                                />
                            </td>
                            <td>
                                <FormControl
                                    placeholder='Введите окончание'
                                    value={module?.end ? module.end.split("T")[0] : ''}
                                    onChange={(e) => changeEnd(e.target.value, module.id)}
                                    type='date'
                                />
                            </td>
                            <td>
                                <Button onClick={() => openThemes(module.id)}>
                                    <BiDotsHorizontal className="d-flex justify-content-between" />
                                </Button>
                                <Button onClick={() => deleteModule(module.id)}>
                                    <FaMinus className="d-flex justify-content-between" />
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <ThemesTable show={themesVisible} onHide={() => setThemesVisible(false)} moduleID={moduleIDToModal} />
        </Container>
    );
});

export default ModulesList;