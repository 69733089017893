import React, { useContext, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Container, Form } from 'react-bootstrap';
import ModulesList from './ModulesList';
import SchoolBook from './SchoolBook';

const TeacherModulesTableNM = observer(() => {
    const { teacher } = useContext(Context)

    const getClassID = () => { 
        if (Object.keys(teacher.selectedClasSubjectSyllabus).length != 0) {
            return teacher.selectedClasSubjectSyllabus?.split('|')[0]
        } else {
            return teacher.classesSubjects[0]?.id.split('|')[0]
        }
    }

    const getSubjectID = () => {
        if (Object.keys(teacher.selectedClasSubjectSyllabus).length != 0) {
            return teacher.selectedClasSubjectSyllabus?.split('|')[1]
        } else {
            return teacher.classesSubjects[0]?.id.split('|')[1]
        }
    }

    return (
        <Container>
            <Form className="mx-2">
                <Form.Select onChange={(e) => teacher.setSelectedClasSubjectSyllabus(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={teacher.selectedClasSubjectSyllabus || teacher.classesSubjects[0]?.id} aria-label="Default select example">
                    {teacher.classesSubjects.map(classub =>
                        <option key={'cs' + classub.id} value={classub.id}>{classub.className + ' / ' + classub.subjectName}</option>
                    )}
                </Form.Select>
            </Form>
            <SchoolBook classID={getClassID()} subjectID={getSubjectID()} />
            <ModulesList classID={getClassID()} subjectID={getSubjectID()} />
        </Container>
    )
});

export default TeacherModulesTableNM;