import { useContext, useEffect, } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '..';
import {  Row, Col } from 'react-bootstrap';

import AdminLessons from '../components/admin/schedules/AdminLessons';

/* import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ru';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'; */ // if using DnD

import { fetchClasses,  fetchSubjects,  userList,   } from '../http/adminApi';

import AdminRefSubjectStar from '../components/admin/refs/AdminRefSubjectStar';
import AdminRefSkillStarDetails from '../components/admin/refs/AdminRefSkillStarDetails';
import AdminStudentSubjectStar from '../components/admin/stars/AdminStudentSubjectStar';
import TutorBar from '../components/tutor/TutorBar';
import TutorAttendanceStat from '../components/tutor/TutorAttendanceStat';
import EventsCalendar from '../components/utilites/EventsCalendar';

const Tutor = observer(() => {
  //  const data = userList()
  //  console.log(data);

//  const { user } = useContext(Context)
  //if (user?.role != 'tutor') return (<>Доступ запрещен!</>);


  const { admin } = useContext(Context)
  const { tutor } = useContext(Context)

  /*   const localizer = momentLocalizer(moment) // or globalizeLocalizer */
  useEffect(() => {
/*     fetchClasses().then(data => tutor.setClasses(data))
    userList('student').then(data => tutor.setStudents(data)) */
    fetchSubjects().then(data => {
      admin.setSubjects(data)
      tutor.setSubjects(data)
      tutor.setSelectedSubject(data[0])
    })
    fetchClasses().then(data => {
      tutor.setClasses(data)
      tutor.setSelectedClass(data[0]) 
      admin.setClasses(data)})
    userList('student').then(data => {
      tutor.setStudents(data)
      tutor.setSelectedStudent(data[0])
      admin.setStudents(data)
    })
  }, [])


  // console.log(admin.users);
  return (
    <>
      <Row className='mt-2'>
        <Col md={3}>
          <TutorBar />
        </Col>
        <Col md={9}>
          {/*  {<AdminBarSub />} */}

          {tutor.selectedSideMenu?.id == '15' && <AdminRefSubjectStar />}
          {tutor.selectedSideMenu?.id == '15' && <AdminRefSkillStarDetails />}
          {tutor.selectedSideMenu?.id == '3' && <AdminLessons editable={false}/>}
          {tutor.selectedSideMenu?.id == '13' && <TutorAttendanceStat />}
          {tutor.selectedSideMenu?.id == '16' && <AdminStudentSubjectStar />}
          {tutor.selectedSideMenu?.id == '9' && <EventsCalendar/> }
        </Col>
      </Row>
    </>
  );
});

export default Tutor;