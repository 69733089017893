import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container, Form, FormControl } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createLessonType, modyfyLessonType, deleteLessonType, fetchClasses, fetchSchedule, modyfySchedule } from '../../http/adminApi';
import { fetchModules } from '../../http/teacherApi';
import ModulesList from './ModulesList';

const TeacherModulesTable = observer(() => {
    const { teacher } = useContext(Context)
    const [classID, setClassID] = useState(teacher.classes[0]?.id)
    const [subjectID, setSubjectID] = useState(teacher.subjects[0]?.id)

    return (
        <Container>
            <Form className="mx-2">
                <Form.Select onChange={(e) => setClassID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={classID} aria-label="Default select example">
                    {teacher.classes.map(clas =>
                        <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => setSubjectID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={subjectID} aria-label="Default select example">
                    {teacher.subjects.map(subject =>
                        <option key={'subject' + subject.id} value={subject.id}>{subject.name}</option>
                    )}
                </Form.Select>
            </Form>
            <ModulesList classID={classID} subjectID={subjectID}/>
        </Container>
    )
});

export default TeacherModulesTable;