import './App.css';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
// import AppRouter, { router } from './components/AppRouter';
import NavBar from './components/NavBar';
import { observer } from "mobx-react-lite";
import { store } from '.';
//import { userList } from './http/adminApi';
//import { check } from './http/userApi';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { MenuItem, MyMenu } from './components/MyMenu';



//import { IUser } from './models/IUser';
/* import Store from './store/store';

export type StoreData = {
  store: Store;
};
 */

//{/* <RequireAuth><Redirect /></RequireAuth> */}
const App: FC = () => {
  console.log("App FC");
  
  /* useEffect(() => {
    if (localStorage.getItem('token')) {
      store.checkAuth();
    }
  }, [])
 */
  //  const { store } = useLoaderData() as StoreData;
  //const [user, setUser] = useState<IUser>();

  /* const data = useLoaderData();
  if (data) {
    const { role }: any = data;
  } */

  const options = [{id: 'a', name: 'b'}];

  return (
    <Layout style={{ minHeight: '100vh'/* '100dvh' */ }}>
      {/* <div className="App"> */}
      {/*       <header className="App-header">
      </header>
 */}      <NavBar />

      <Layout>{/* 
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width={260} style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          // background: colorBgContainer
        }}>
                       <MainMenu /> 
                      <div
              id="detail"
              className={
                navigation.state === 'loading'
                  ? 'loading'
                  : ''
              }
            >---</div> 


        </Sider> */}
        <Layout className="site-layout" /* style={{ marginLeft: 240, padding: '0 14px 14px' }} */>
          {/*           <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb> */}
          <Content
            style={{
              padding: 14,
              margin: 0,
              minHeight: 300,
              /* background: colorBgContainer, */
            }}
          >
            <div id="detail">
              {/* <MyMenu options={options}/><MenuItem/><MyMenu/> */}
              <Outlet />
            </div>

          </Content>
        </Layout>
      </Layout>

      {/* </div> */}
    </Layout >
  );
};


export const rootLoader = async ({ request, params }: any) => {
  console.log('root loader');
  
  await store.checkAuth();
  return null;
}

export default observer(App);
