//import { connect } from 'http2';
import React, { useState, useContext, useEffect, FC } from 'react';
import { Button, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { redirect, useLocation } from 'react-router-dom';
import { userLogin } from '../http/userApi';
import { LOGIN_ROUTE, ADMIN_ROUTE, STUDENT_ROUTE, TEACHER_ROUTE, PARENT_ROUTE, TUTOR_ROUTE } from '../utils/consts';
import { observer } from "mobx-react-lite";
import { Context, store } from "../index";
import { useNavigate, Form } from 'react-router-dom';
//import { user } from '../index';

export async function action({ request, params }: any) {
  const formData = await request.formData();
  const {login, password} = Object.fromEntries(formData);
  console.log("auth.tsx");

  await store.login(login, password);

  return redirect('..');
}

const Auth: FC = () => {

  const { /* user,  */store } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();
  //  const {signin} = useAuth();

  const fromPage = location.state?.from?.pathname || '/';

  const isLogin = location.pathname === LOGIN_ROUTE
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  /*     useEffect(() => {
        if (user.isAuth) {
          switch (user.user.role) {
            case 'ADMIN':navigate(ADMIN_ROUTE, { replace: true }) // навигация после логина
            break
            case 'student':navigate(STUDENT_ROUTE, { replace: true }) // навигация после логина
            break
            case 'teacher':navigate(TEACHER_ROUTE, { replace: true }) // навигация после логина
            break
            case 'tutor':navigate(TUTOR_ROUTE, { replace: true }) // навигация после логина
            break
            case 'parent':navigate(PARENT_ROUTE, { replace: true }) // навигация после логина  
            break          
          }  
        }
  
      }, []) 
   */
  const signIn = async (event: any) => {
    try {
      event.preventDefault();
      store.login(login, password);
      //      user.login(login, password);


      if (fromPage === 'notfound') navigate('/login', { replace: true });
      if (fromPage != 'login') navigate(fromPage, { replace: true });

      /*       await userLogin(login, password).then((data) => {
              console.log("LOGIN", data, fromPage, user);
              //signin(login, password, () => {navigate('/', {replace: true})});
              user.setUser(data)
              user.setIsAuth(true)
              user.setRole(data.role)
              console.log("LOGIN - " + data.role);
              data.roles ? user.setRoles(JSON.parse(data.roles)) : user.setRoles([])
      
               switch (data.role) {
                case 'ADMIN':navigate(ADMIN_ROUTE, { replace: true }) // навигация после логина
                break
                case 'student':navigate(STUDENT_ROUTE, { replace: true }) // навигация после логина
                break
                case 'teacher':navigate(TEACHER_ROUTE, { replace: true }) // навигация после логина
                break
                case 'tutor':navigate(TUTOR_ROUTE, { replace: true }) // навигация после логина
                break
                case 'parent':navigate(PARENT_ROUTE, { replace: true }) // навигация после логина  
                break          
              }   
             }) */
    } catch (e) {
      // alert(e?.response?.data?.message)
    }
  }

  const navAfterLogin = async () => {
    await navigate(ADMIN_ROUTE, { replace: true })
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      console.log('enter press here! ')
      signIn(event)
    }
  }

  return (
    <Container
      /*  onKeyPress={handleKeyPress} */
      className="d-flex justify-content-center align-items-center"
      style={{ height: window.innerHeight - 54 }}
    >
      <Card style={{ width: 600 }} className="p-5">
        <h2 className="m-auto">Авторизация</h2>
        {/* fromPage */}
        <Form className="d-flex flex-column" method='post'>
          <input name='login' placeholder="Введите ваш логин..." className="mt-3"/>
          <input name='password' placeholder="Введите ваш пароль..." className="mt-3" type="password"/>
          <Button type="submit" className='mt-3 align-self-end'>Войти</Button>
          {/* <Form.Control
            className="mt-3"
            placeholder="Введите ваш логин..."
            value={login}
            onChange={e => setLogin(e.target.value)}
          />
          <Form.Control
            className="mt-3"
            placeholder="Введите ваш пароль..."
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
          <Button
            className='mt-3 align-self-end'
            variant={'outline-success'}
            onClick={signIn}
          >
            Войти
          </Button> */}
        </Form>
      </Card>
    </Container>
  );
};

export default observer(Auth);
