import React, { useEffect, useContext, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import { fetchStarSData} from '../../../http/studentApi';
import RadarChart from '../../utilites/star/RadarChart';
import { Context } from '../../..';
import { Card, Col, message, Row, Select, Slider } from 'antd';
import { fetchStarSubData, modyfyStarSubData } from '../../../http/teacherApi';
import TeacherStudentSubjectStarTips from '../../utilites/star/StarTips';
import EditStar from '../../utilites/star/EditStar';
import { fetchTipsData, getPermissionCreateTact, setPermissionCreateTact } from '../../../http/adminApi';
import AdminSelectClassStudent from './AdminSelectClassStudent';
import { fetchSubjectsTacts, fetchStar1DataBSID, fetchSkillTacts } from '../../../http/parentApi';
import { FaPlus } from 'react-icons/fa';

const AdminStudentStars = observer(() => {
    const { admin } = useContext(Context)
    const [subjectTacts, setSubjectsTacts] = useState([]);
    const [skillTacts, setSkillTacts] = useState([]);
    const [star1, setStar1] = useState([])
    const [starS, setStarS] = useState([])
    const [permission, setPermission] = useState(false)



    useEffect(() => {
        loadData()
    }, [admin.selectedStudent])

    const chartSize = 450;
    const numberOfScale = 5;


    const [tipsDataName, setTipsDataName] = useState('')
    const [tipsDataChoice, setTipsDataChoice] = useState(0)
    const [stateSaveButton, setStateSaveButton] = useState(true)
    const [tipsTextData, setTipsTextData] = useState({})

    const loadData = async () => {
        //const dataS = await fetchStarSData()
        if (admin.selectedStudent?.id) {
            const permission = await getPermissionCreateTact(admin.selectedStudent?.id);
            console.log("permission", permission);
            setPermission(permission)
            const data1 = await fetchStar1DataBSID(admin.selectedStudent?.id)
            setStar1(data1)
            const dataS = await fetchStarSubData(admin.selectedStudent?.id)
            setStarS(dataS)
            const tips = await fetchTipsData('tutor')
            setTipsTextData(tips)
            const subjectTacts = await fetchSubjectsTacts(admin.selectedStudent?.id);
            setSubjectsTacts(subjectTacts);
            const skillTacts = await fetchSkillTacts(admin.selectedStudent?.id);
            setSkillTacts(skillTacts);

        } else {
            setStarS([])
            setTipsTextData({})
        }
    }

    const change1 = (name, value) => {
        let star = starS
        star[1][name] = value
        setStarS(JSON.parse(JSON.stringify(star)))
        setTipsDataName(name)
        setTipsDataChoice(value)
        setStateSaveButton(false)
        //    console.log("starS",starS);
    }

    const saveData = async () => {
        try {
            const formData = new FormData()
            formData.append('studentID', admin.selectedStudent?.id)
            formData.append('starData', JSON.stringify(starS))
            modyfyStarSubData(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                    loadData()
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    }

    const savePermission = async () => {
        const ret = await setPermissionCreateTact(admin.selectedStudent?.id);
        if (ret == "ok") {
            message.success("Создание записи разрешено");
            setPermission(true);
        }
    }


    const handleChangeTacts = async (value) => {
        var data = [[], []];
        if (admin.selectedStudent?.id) {
            data = await fetchStar1DataBSID(admin.selectedStudent?.id, value)
        }

        setStar1(data);

    };

    const handleChangeSkillTacts = async (value) => {
        var data = [[], []];
        if (admin.selectedStudent?.id) {
            data = await fetchStarSubData(admin.selectedStudent?.id, value)
        }

        setStarS(data);

    };


    return (<>
        <AdminSelectClassStudent />
        <Card title="Индивидуальный образовательный маршрут" key={"a" + admin.selectedStudent?.id} extra={<Button disabled={permission} onClick={() => {savePermission()}} className='float-end'>{/* <FaPlus className="d-flex justify-content-between" /> */}Дать возможность создать новую звезду</Button>}>
            <div style={{ width: '100%', }}>
                <Select
                    mode='multiple'
                    placeholder='Выберите такт'
                    options={subjectTacts}
                    style={{ width: '100%' }}
                    onChange={handleChangeTacts}
                />
            </div>
            {star1.length > 0 &&
                <>
                    {star1.map((item, index) =>
                        <EditStar
                            header={item.title}
                            starS={item.star}

                            setStarS={setStar1}
                            //tipsTextData={tipsTextData}
                            starLayer={0}
                            //saveData={saveData}
                            greenDesc='- планируемый уровень'
                            yellowDesc='- текуший уровень'
                            editable={false}
                        />
                    )}
                </>
            }
        </Card>
        <Card title="Индивидуальный план развития" key={"b" + admin.selectedStudent?.id}>
            <div style={{ width: '100%', }}>
                <Select
                    mode='multiple'
                    placeholder='Выберите такт'
                    options={skillTacts}
                    style={{ width: '100%' }}
                    onChange={handleChangeSkillTacts}
                />
            </div>

            {starS.length > 0 &&
                <>
                    {starS.map((item, index) =>
                        <EditStar
                            header={item.title}
                            starS={item.star}
                            //                            setStarS={setStarS}
                            tipsTextData={tipsTextData}
                            starLayer={0}
                            //saveData={saveData}
                            greenDesc='- самооценка студента'
                            yellowDesc='- оценка тьютора'
                            editable={false}
                        />
                    )}
                </>
            }
        </Card>
    </>
    );
});

export default AdminStudentStars;