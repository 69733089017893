import React, { useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchScheduleGridItemsByTeacher } from '../http/adminApi';
import TeacherBar from '../components/teacher/TeacherBar';
import TeacherClasses from '../components/teacher/classes/TeacherClasses';
import TeacherStudents from '../components/teacher/classes/TeacherStudents';
import TeacherStudent from '../components/teacher/classes/TeacherStudent';
import TeacherSetScore from '../components/teacher/classes/TeacherSetScore';
import TeacherSubBar from '../components/teacher/TeacherSubBar';
import TeacherModulesTable from '../components/teacher/TeacherModulesTable';
import { fetchTeacherClasses, fetchTeacherClassesSubjects, fetchTeacherSchedule, fetchTeacherSubjects } from '../http/teacherApi';
import TeacherShedule from '../components/teacher/TeacherSheduleNew';
import AttendanceEdit from '../components/teacher/attendance/AttendanceEdit';
import TeacherModulesTableNM from '../components/teacher/syllabus/TeacherModulesTableNM';
import ThemesTable from '../components/teacher/syllabus/ThemesTable';
import RoutesTable from '../components/teacher/syllabus/RoutesTable';
import DescriptionsTable from '../components/teacher/syllabus/DescriptionsTable';
import TeacherStudentsMass from '../components/teacher/classes/TeacherStudentsMass';
import TeacherSetScoreNL from '../components/teacher/classes/TeacherSetScoreNL';
import TeacherAttendanceStat from '../components/teacher/statistic/TeacherAttendanceStat';
import EventsCalendar from '../components/utilites/EventsCalendar';
import TeacherSheduleOld from '../components/teacher/TeacherShedule';
import { user } from '../';

const Teacher = observer(() => {

  //if (user?.role != 'teacher') return (<>Доступ запрещен! (У)</>);

  const { teacher } = useContext(Context)
  const { admin } = useContext(Context)

  useEffect(() => {
    //console.log("STUDENT",user.user.id);
    //getClassByStudent(user.id).then(data => setStudentData(data))
    // TODO добыть класс из свойств пользователя.
    //getClassByUserID(user.user.id).then(clas => fetchSchedule(clas).then(data => student.setStudentSchedule(data)))
    //console.log("class",clas);
    //fetchSchedule(clas).then(data => student.setStudentSchedule(data))
    fetchTeacherSubjects().then(data => teacher.setSubjects(data))
    fetchTeacherClasses().then(data => teacher.setClasses(data))
    fetchTeacherClassesSubjects().then(data => teacher.setClassesSubjects(data))
    fetchTeacherSchedule().then(data => teacher.setTeacherSchedule(data))
    fetchScheduleGridItemsByTeacher().then(data => admin.setLessonsInterval(data))
  }, [])

  return (
    <>


      <Row className='mt-2'>
        <Col md={3}>
          <TeacherBar />
        </Col>
        <Col md={9}>
          {<TeacherSubBar />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '1') && <TeacherShedule />}
          {/* {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '2') && <TeacherModulesTable />} */}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '4') && <TeacherClasses nextID={41} />}
          {teacher.selectedSubMenu?.id == '41' && <TeacherStudents nextID={42} />}
          {teacher.selectedSubMenu?.id == '42' && <TeacherStudent />}
          {teacher.selectedSubMenu?.id == '43' && <TeacherSetScoreNL />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '7') && <TeacherClasses nextID={71} />}
          {teacher.selectedSubMenu?.id == '71' && <TeacherStudentsMass />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '5') && <AttendanceEdit />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '6') && <TeacherModulesTableNM />}
          {teacher.selectedSubMenu?.id == '61' && <ThemesTable />}
          {teacher.selectedSubMenu?.id == '62' && <RoutesTable />}
          {teacher.selectedSubMenu?.id == '63' && <DescriptionsTable />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '8') && <TeacherClasses nextID={81} />}
          {teacher.selectedSubMenu?.id == '81' && <TeacherAttendanceStat />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '9') && <EventsCalendar />}
        </Col>
      </Row>
    </>
  );
});

export default Teacher;