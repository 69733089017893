export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const START_ROUTE = '/'
export const STUDENT_ROUTE = '/student'
export const TEACHER_ROUTE = '/teacher'
export const TUTOR_ROUTE = '/tutor'
export const PARENT_ROUTE = '/parent'
export const TEST_ROUTE = '/test'

//export const CLIENT_VERSION = process.env.CLIENT_VERSION
export const CLIENT_VERSION = '0.161'
export const CONTROL_WORK_TYPE = 15 // id контрольной работы в типе контроля темы в силабусе-маршруте
export const EMPTY_SCHEDULE = 0 // id пустого предмета в расписании (предмет по умолчанию для отображения)