import React, { useContext, useEffect} from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../';
import { Row, Col } from 'react-bootstrap';
import StudentBar from '../components/student/StudentBar';
import StudentsShedule from '../components/student/StudentsShedule';
import { fetchModules } from '../http/teacherApi';
import { getClassByUserID, getSubjectsByClassID } from '../http/studentApi';
import StudentStar from '../components/student/star/StudentStar';
import StudentSilabusNM from '../components/student/syllabus/StudentSilabusNM';
import StudentSillabus from '../components/student/syllabus/StudentSillabus';
import StudentResponses from '../components/student/syllabus/StudentResponses';
import StudentSubBar from '../components/student/StudentSubBar';
import SchcollBook from '../components/utilites/SchcollBook';
import StudentAttendanceStat from '../components/student/statistic/StudentAttendanceStat';
import StudentStarL2 from '../components/student/star/StudentStarL2';
import StudentStarL3 from '../components/student/star/StudentStarL3';
import StudentStudentStat from '../components/student/statistic/StudentStudentStat';
import EventsCalendar from '../components/utilites/EventsCalendar';
import StudentPlansCalendar from '../components/student/StudentPlansCalendar';
import StudentPlansTable from '../components/student/StudentPlansTable';
import { Layout } from 'antd';
//import { user } from '../';

const Student = observer(() => {
  const { store } = useContext(Context)
  //if (user?.role != 'student') return (<>Доступ запрещен!</>);

  const { student } = useContext(Context)


  useEffect(() => {
    loadData(store.user.id)
    //console.log("STUDENT",user.user.id);
    // TODO добыть класс из свойств пользователя.
    //    getClassByUserID(user.user.id).then(clas => student.setStudentClass(clas))
    //fetchSchedule(student.studentClass).then(data => student.setStudentSchedule(data))
    //    getClassByUserID(user.user.id).then(clas => fetchSchedule(clas).then(data => student.setStudentSchedule(data)))
    //   getSubjectsByClassID(student.studentClass).then(data => student.setStudentSubjects(data)) 
    //    getModulesByClassID(student.studentClass).then(data => student.setStudentModules(data)) 
  }, [])

  const loadData = async (userID) => {
    const classID = await getClassByUserID(userID)
    student.setStudentClass(classID)
    student.setStudentData(store.user)
    // await fetchModules(classID, student.studentSubjects[0].id).then(data => student.setStudentModules(data))
    //await fetchSchedule(classID).then(data => student.setStudentSchedule(data))
    await getSubjectsByClassID(classID).then(data => student.setStudentSubjects(data))
    await fetchModules(classID, student.studentSubjects[0].id).then(data => student.setStudentModules(data))
    //await fetchScheduleGrid(classID).then(data => admin.setLessonsInterval(data))

    //await getModulesByClassID(classID).then(data => student.setStudentModules(data)) 
    //const data = await fetchModules(classID, subjectID)
    //setModules(data)
  }

  return (
    <Layout style={{minHeight: '100dvh'}}>
      

      <Row className='mt-2'>
        <Col md={3}>
          <StudentBar />
          {student.selectedSideMenu?.id == '6' &&
            <SchcollBook
              classID={student.studentClass}
              subject={student.selectedSubject}
            />}
        </Col>
        <Col md={9}>
          <StudentSubBar />
          {student.selectedSideMenu?.id == '6' &&
            <SchcollBook
              classID={student.studentClass}
              subject={student.selectedSubject}
            />}
          {Boolean(!student.selectedSubMenu?.id & student.selectedSideMenu?.id == '1') && <StudentsShedule /* schedule={student.studentSchedule} */ mode={'student'} />}
          {/*    {Boolean(!student.selectedSubMenu?.id & student.selectedSideMenu?.id == '2') && <StudentSilabus />} */}
          {Boolean(!student.selectedSubMenu?.id & student.selectedSideMenu?.id == '4') && <StudentStar />}
          {student.selectedSubMenu?.id == '41' && <StudentStarL2 />}
          {student.selectedSubMenu?.id == '42' && <StudentStarL3 />}

          {Boolean(!student.selectedSubMenu?.id & student.selectedSideMenu?.id == '6') && <StudentSilabusNM student={student} />}

          {student.selectedSubMenu?.id == '61' && <StudentSillabus student={student} />}
          {student.selectedSubMenu?.id == '62' && <StudentResponses student={student} />}

          {Boolean(!student.selectedSubMenu?.id & student.selectedSideMenu?.id == '7') && <StudentAttendanceStat studentID={store.user.id} />}
          {Boolean(!student.selectedSubMenu?.id & student.selectedSideMenu?.id == '8') && <StudentStudentStat mode={'student'} studentID={store.user.id} />}

          {Boolean(!student.selectedSubMenu?.id & student.selectedSideMenu?.id == '9') && <EventsCalendar />}

          {student.selectedSideMenu?.id == '5' && <StudentPlansCalendar />}
          {student.selectedSideMenu?.id == '5' && <StudentPlansTable />}
          {/* { student.selectedSideMenu?.id=='3' && <StudentsShedule /> } */}
        </Col>
      </Row>
    </Layout>
  );
});

export default Student;