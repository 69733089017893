import React, { useEffect, useContext, useState } from 'react';
//import Table from 'react-bootstrap/Table';
import { Table, Tooltip } from 'antd';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import { Context } from '../../..';
import { Col, InputNumber, message, Row } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import { DatePicker } from 'antd';
import { fetchAttendanceStat } from '../../../http/teacherApi';
import AttendanceStudentHist from './AttendanceStudentHist';
import AttendanceStudentStatus from './AttendanceStudentStatus';
import TeacherScoreSheet from './TeacherScoreSheet';

const { RangePicker } = DatePicker;

const TeacherAttendanceStat = observer(() => {
    const { teacher } = useContext(Context)

    const dateFormat = 'DD.MM.YYYY';
    const dateFormatForDB = 'YYYY-MM-DD';

    const firstColumn = {
        title: `${teacher.selectedClasSubject.className} / ${teacher.selectedClasSubject.subjectName}`,
        dataIndex: 'name',
        fixed: 'left',
        width: 200,
    }
    const lastColumn = {
        title: 'Сводная гистограмма',
        dataIndex: 'hist',
        fixed: 'right',
        width: 300,
        align: 'center',
    }

    const [studentsList, setStudentsList] = useState([])
    const [columns, setColumns] = useState([firstColumn, lastColumn])

//    const [begin, setBegin] = useState(moment(moment.now() - 604800000))
//    const [end, setEnd] = useState(moment(moment.now()))

    useEffect(() => {
        loadData(teacher.statBegin, teacher.statEnd)
    }, [])

    const loadData = async (begin, end) => {
        const data = await fetchAttendanceStat(begin.format(dateFormatForDB), end.format(dateFormatForDB), teacher.selectedClasSubject.classID, teacher.selectedClasSubject.subjectID)

        let colData = [firstColumn]
        data.columns.map((col) => {
            colData.push({
                title: <Tooltip title={col.begin + '-' + col.end} color="grey"><div style={{writingMode:'sideways-lr'}}>{col.date}</div></Tooltip>,
                dataIndex: col.id,
                width: 30,
            })
        })
        colData.push(lastColumn)
        setColumns(colData)

        setStudentsList(data.students.map(stu => {
            for (var key in stu) {
                if (key != 'id' && key != 'name' && key != 'hist' && key != 'key') {
                    stu[key] = <AttendanceStudentStatus cell={stu[key]} />
                }
            }
            return {
                ...stu,
                ['key']: stu.id,
                ['hist']: <AttendanceStudentHist hist={stu.hist} />
            }
        }))

    }

    const changePeriod = (period) => {
        teacher.setStatBegin(period[0])
        teacher.setStatEnd(period[1])
        //setBegin(period[0])
        //setEnd(period[1])
        loadData(period[0],period[1])
    }

    return (
        <Container>
            <RangePicker
                //value={[begin, end]}
                value={[teacher.statBegin, teacher.statEnd]}
                format={[dateFormat, dateFormat]}
                allowClear={false}
                onChange={(value) => changePeriod(value)}
            /*  disabledDate={disabledDate}
             onCalendarChange={(val) => setDates(val)}
             onChange={(val) => setValue(val)}
             onOpenChange={onOpenChange} */
            />

            <Table
                style={{ marginTop: '15px' }}
                columns={columns}
                dataSource={studentsList}
                pagination={false}
              /*   pagination={{
                    pageSize: 50,
                }} */
                scroll={{
                    x: 500,
                }}
            />
            <TeacherScoreSheet begin={teacher.statBegin} end={teacher.statEnd}></TeacherScoreSheet>
        </Container>
    );
});

export default TeacherAttendanceStat;