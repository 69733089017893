import React, { useEffect, useContext, useState } from 'react';
//import Table from 'react-bootstrap/Table';
import { Table, Tooltip } from 'antd';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import { Context } from '../../..';
import { Col, InputNumber, message, Row } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import { DatePicker } from 'antd';
import { fetchAttendanceStat, fetchScoreSheet } from '../../../http/teacherApi';
import AttendanceStudentHist from './AttendanceStudentHist';
import AttendanceStudentStatus from './AttendanceStudentStatus';

const { RangePicker } = DatePicker;

const TeacherScoreSheet = observer(({begin, end}) => {
    const { teacher } = useContext(Context)

    const dateFormat = 'DD.MM.YYYY';

    const firstColumn = {
        title: `${teacher.selectedClasSubject.className} / ${teacher.selectedClasSubject.subjectName}`,
        dataIndex: 'name',
        fixed: 'left',
        width: 200,
    }
    const lastColumn = {
        title: <div style={{writingMode:'sideways-lr'}}>Средняя</div>,
        dataIndex: 'mean',
        fixed: 'right',
        width: 30,
        align: 'center',
    }

    const [studentsList, setStudentsList] = useState([])
    const [columns, setColumns] = useState([firstColumn, lastColumn])

    const sList = {"columns":[{"id":"ID20","themeName":"Введение в математику"},{"id":"ID21","themeName":"Выведение из математики"}],
            "students":[
                {"id":162,"name":"Агровиченко Софья Николаевна","mean":86,"ID20":{"score":80},"ID21":{"score":90}},
                {"id":163,"name":"Беспалова Виринея Дмитриевна","mean":57,"ID20":{"score":51},"ID21":{"score":60}},
                {"id":164,"name":"Ворсин Андрей Александрович","mean":65,"ID20":{"score":60},"ID21":{"score":70}},
            ]}

    useEffect(() => {
        loadData(begin, end)
    }, [begin, end])

    const loadData = async (begin, end) => {
        //const data = await fetchScoreSheet(begin.format(dateFormat), end.format(dateFormat), teacher.selectedClasSubject.classID, teacher.selectedClasSubject.subjectID)
        const data = sList

        let colData = [firstColumn]
        data.columns.map((col) => {
            colData.push({
                //title: <Tooltip title={col.begin + '-' + col.end} color="grey"><div style={{writingMode:'sideways-lr'}}>{col.date}</div></Tooltip>,
                title: <div style={{writingMode:'sideways-lr'}}>{col.themeName}</div>,
                dataIndex: col.id,
                width: 30,
            })
        })
        colData.push(lastColumn)
        setColumns(colData)

        setStudentsList(data.students.map(stu => {
            for (var key in stu) {
                if (key != 'id' && key != 'name' && key != 'mean' && key != 'key') {
                    stu[key] = stu[key].score 
                }
            }
            return {
                ...stu,
                ['key']: stu.id,
                ['mean']: stu.mean
            }
        }))

    }

    return (
            <Table
                style={{ marginTop: '15px' }}
                columns={columns}
                dataSource={studentsList}
                pagination={false}
              /*   pagination={{
                    pageSize: 50,
                }} */
                scroll={{
                    x: 500,
                }}
            />
    );
});

export default TeacherScoreSheet;