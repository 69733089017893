import { makeAutoObservable } from "mobx";
import { IUser } from "../models/IUser";
import AuthService from "../services/AuthService";

export default class Store {
    user = {} as IUser;
    isAuth = false;
    isLoading = false;
    roles = [];
    role : string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setUser(user: IUser) {
        this.user = user;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setRole(role: string) {
        this.role = role;
    }

    setRoles(roles: string) {
        this.roles = roles ? JSON.parse(roles) : [];
    }

    async login(login: string, password: string) {
        this.setLoading(true);
        try {
            const response = await AuthService.login(login, password);
            //console.log("response", response);

            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            this.setRoles(this.user.roles);
            this.setRole(response.data.user.role);

         } catch (e) {
            //console.log(e.response?.data?.message);
        } finally {
            this.setLoading(false);
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser({} as IUser);
            this.setRoles('');
        } catch (e) {
            //console.log(e.response?.data?.message);
        }
    }

    async checkAuth() {
        console.log('==checkAuth==================================');

        this.setLoading(true);
        try {
            const response = await AuthService.refresh();
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            this.setRoles(this.user.roles);
            //this.setRole(this.roles[0]);
        } catch (e) {
            console.log('==ERROR checkAuth==================================');
            this.setAuth(false);
            this.setUser({} as IUser);
            this.setRoles('');
            this.setRole('');
        } finally {
            this.setLoading(false);
        }
    }
}