import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Container, Form } from 'react-bootstrap';
import { fetchModules, fetchThemes } from '../../../http/teacherApi';
import { Card } from 'antd';

//Силпабус студента
const StudentSilabusNM = observer(({ student }) => {
    // const { student } = useContext(Context)
    const [modules, setModules] = useState([])
    const [themes, setThemes] = useState([])
    //    const [selectedSubject, setSelectedSubject] = useState(null)
    //    const [selectedModule, setSelectedModule] = useState({})

    useEffect(() => {
        // loadData()
        if (!student.selectedSubject) {
            student.setSelectedSubject(student.studentSubjects[0]?.id)
            selectSubject(student.studentSubjects[0]?.id)
        } else {
            selectSubject(student.selectedSubject)
        }
    }, [])

    useEffect(() => {
        loadTableData()
    }, [student.selectedModule])

    const loadData = async () => {
        //console.log("LOAD", student.selectedSubject, student.selectedModule);
        //setSelectedSubject(student.studentSubjects[0]?.id)
        if (!student.selectedSubject) {
            student.setSelectedSubject(student.studentSubjects[0]?.id)
            await fetchModules(student.studentClass, student.studentSubjects[0]?.id).then(data => setModules(data))
        } else {
            await fetchModules(student.studentClass, student.selectedSubject).then(data => setModules(data))
        }
        //student.setSelectedSubject(student.studentSubjects[0]?.id)
        //        await fetchModules(student.studentClass, student.studentSubjects[0]?.id).then(data => setModules(data))

        //setSelectedModule(student.studentModules[0])
        if (!student.selectedModule?.id) {
            student.setSelectedModule(student.studentModules[0])
            await fetchThemes(student.studentModules[0]?.id).then(data => setThemes(data))
        } else {

            await fetchThemes(student.selectedModule.id).then(data => setThemes(data))
        }

        //       student.setSelectedModule(student.studentModules[0])
        //       await fetchThemes(student.studentModules[0]?.id).then(data => setThemes(data))
    }

    const loadTableData = async () => {
        if (student.selectedModule?.id) {
            await fetchThemes(student.selectedModule.id).then(data => setThemes(data))
        }
    }

    const selectSubject = async (subjectID) => {
        student.setSelectedSubject(subjectID)
        //setSelectedSubject(subjectID)
        const data = await fetchModules(student.studentClass, subjectID)
        setModules(data)
        //await selectModule(data[0].id)

        student.setSelectedModule(data.find((modul) => modul.selected))

        await fetchThemes(data[0].id).then(data => setThemes(data))
    }

    const selectModule = async (moduleID) => {
        student.setSelectedModule(modules.find(modul => modul.id == moduleID))
        await fetchThemes(moduleID).then(data => setThemes(data))
    }

    function click(theme) {
        student.setSelectedTheme(theme)
        student.setSelectedSubMenu({ id: 61 })
    }

    return (
        <Card>
            <Form className="mx-2">
                <Form.Select onChange={(e) => selectSubject(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={student.selectedSubject || student.studentSubjects[0]?.id} aria-label="Default select example">
                    {student.studentSubjects.map(subject =>
                        <option key={subject.id} value={subject.id}>{subject.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => selectModule(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={student.selectedModule?.id || student.studentModules[0]?.id} aria-label="Default select example">
                    {modules.map(module =>
                        <option key={module.id} value={module.id}>{module.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Table striped bordered hover style={{ 'marginTop': '15px' }}>
                <thead>
                    <tr>
                        <th colSpan="100%">Модуль {student.selectedModule?.index} : {student.selectedModule?.name}</th>
                    </tr>
                    <tr>
                        <th colSpan="100%">Начало: {student.selectedModule?.begin?.split('T')[0]}    Окончание: {student.selectedModule?.end?.split('T')[0]}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Название темы</th>
                        <th>Дата</th>
                        {/* <th>Самооценка</th> */}
                        {/* <th>Оценка учителя</th> */}
                        <th>Оценка</th>
                    </tr>

                    {themes.map(theme =>
                        <tr>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'name' + theme.id}
                            >
                                {theme.selected ?
                                    <div class='btn btn-primary'>{theme.name}</div>
                                    //<div style={{color:"blue"}}>{theme.name}</div>
                                    :
                                    theme.name
                                }
                            </td>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'end' + theme.id}
                            >
                                {/* {console.log("the",theme)} */}
                                {theme?.end?.split('T')[0]}
                            </td>
                            {/*      <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'sel' + theme.id}
                            >
                                {theme?.studentScore}
                            </td>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'sco' + theme.id}
                            >
                                {theme?.teacherScore}
                            </td> */}
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'sco' + theme.id}
                            >
                                {theme?.score}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    );
});

export default StudentSilabusNM;