import moment from 'moment'
import 'moment/locale/ru'
import React, { useMemo, useState, useEffect, useContext } from 'react';

import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';

//import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
//import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'; // if using DnD

//import events from '../events'
import * as dates from '../utils/dates'
import './navbar.css'
import { Context } from '..';
import { observer } from 'mobx-react-lite';

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.import withDragAndDrop from 

const MyCalendar = observer((props) => {

//observer(function MyCalendar(props) {

//  const DnDCalendar = withDragAndDrop(Calendar);
  const localizer = momentLocalizer(moment);

  const { admin } = useContext(Context)
  const [events, setEvents] = useState([])

  /* = [
    {
      start: moment().toDate(),
      end: moment()
        .add(1, "days")
        .toDate(),
      title: "Some title11"
    }
  ]
 */

  const format = "YYYY-MM-DD HH:mm"

/*   useEffect(() => {
    admin.calendarEvents.map(event => setEvents([{start: moment(event.date, format).toDate(), end: moment(event.end, format).toDate(), title: event.name}, ...events]))
  }, []) */

  useEffect(() => {
    let tempEvents = new Array()
    admin.calendarEvents.map(event => {
      tempEvents = [...tempEvents, {id: event.id, start: moment(event.date, format).toDate(), end: moment(event.end, format).toDate(), title: event.name}]
      //setEvents([{start: moment(event.date, format).toDate(), end: moment(event.end, format).toDate(), title: event.name}, ...events])
      //console.log("dssf", event, tempEvents);
    })
    setEvents(tempEvents)
  }, [admin.calendarEvents])


  const lang = {
    'ru': {
      week: 'Неделя',
      work_week: 'Рабочая неделя',
      day: 'День',
      month: 'Месяц',
      previous: '<<',
      next: '>>',
      today: 'Сегодня',
      agenda: 'События',

      date: 'Дата',
      time: 'Время',
      event: 'Событие',
      allDay: 'Весь день',
      yesterday: 'Вчера',
      tomorrow: 'Завтра',

      noEventsInRange: 'Нет событий в этом периоде',

      showMore: (total) => `+${total} записей`,
    }
  }


 

  const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        backgroundColor: 'lightblue',
      },
    }
    )

  const { components, messages, defaultDate, max, min, views } = useMemo(
    () => ({
      components: {
        //            timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new moment(moment.now()).toDate(),
      max: new Date(2050, 0, 1, 21, 0, 0, 0),
      min: new Date(1972, 0, 1, 7, 0, 0, 0),
      views: Object.keys(Views).map((k) => Views[k]),
      messages: lang['ru'],
    }),
    []
  )

  const backgroundEvents = [
    {
      id: 0,
      title: 'Available for Clients',
      start: new Date(2023, 9, 25, 6),
      end: new Date(2023, 9, 25, 18),
    },
  ]

  return (
    <div className='calendar'>
      <Calendar
                backgroundEvents={backgroundEvents}
                dayLayoutAlgorithm="no-overlap"
      
        components={components}
        defaultDate={defaultDate}
        culture={"ru"}
        showMultiDayTimes
        localizer={localizer}
        events={events}
        min={min}
        max={max}
        defaultView={Views.MONTH}
        //startAccessor="start"
        //endAccessor="end"
        messages={messages}
        style={{ fontSize: '0.65rem' }}
//        style={{ fontSize: 'smaller' }}
      />
    </div>
  )
})

export default MyCalendar;