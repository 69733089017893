import { message } from "antd";
import { $authHost } from "./index";

export const getClassByUserID = async (userID) => {
    try {
        const { data } = await $authHost.get('api/services/classbyuserid/' + userID)
        return data
    } catch (e) {
        message.error("Ошибка получения класса по ID студента!");
        return null;
    }
}

export const getSubjectsByClassID = async (classID) => {
    try {
        const { data } = await $authHost.get('api/services/subjectsbyclassid/' + classID)
        return data
    } catch (e) {
        message.error("Ошибка получения предметов по ID класса!");
        return null;
    }
}

export const getModulesByClassID = async (classID) => {
    const { data } = await $authHost.get('api/services/nodulesbyclassid/' + classID)
    return data
}

export const fetchDescriptionsByThemeID = async (themeID, studentID) => {
    const { data } = await $authHost.get('api/silabus/descriptionsbytheme/' + themeID + '/' + studentID)
    return data
}

export const fetchResponses = async (descID, studentID) => {
    const { data } = await $authHost.get('api/silabus/responses/' + descID + '/' + studentID)
    return data
}

export const createResponse = async (descID, studentID) => {
    const { data } = await $authHost.post('api/silabus/response', { descID, studentID })
    return data
}

export const modyfyResponse = async (responseID, answer, studentScore, teacherScore, userID) => {
    const { data } = await $authHost.put('api/silabus/response', { responseID, answer, studentScore, teacherScore, userID })
    return data
}

export const fetchStar1Data = async (tacts) => {
    try {
        const { data } = await $authHost.get('api/student/star1?tacts=' + tacts)
        return data
    } catch (e) {
        return null
    }
}

export const fetchStar2Data = async (subjectID) => {
    const { data } = await $authHost.get('api/student/star2?subjectID=' + subjectID)
    return data
}

export const fetchStar3Data = async (moduleID) => {
    const { data } = await $authHost.get('api/student/star3?moduleID=' + moduleID)
    return data
}

export const fetchStarSData = async (tacts) => {
    try {
        const { data } = await $authHost.get('api/student/starS?tacts=' + tacts)
        return data
    } catch (e) {
        return null
    }
}

export const modyfyStarSubData = async (formData) => {
    const { data } = await $authHost.put('api/student/starS/', formData)
    return data
}

export const modyfyStarEduData = async (formData) => {
    const { data } = await $authHost.put('api/student/star1/', formData)
    return data
}

export const modyfyStarEduDataL2 = async (formData) => {
    const { data } = await $authHost.put('api/student/star2/', formData)
    return data
}

export const modyfyStarEduDataL3 = async (formData) => {
    const { data } = await $authHost.put('api/student/star3/', formData)
    return data
}

export const fetchStudentAttendanceStat = async (begin, end, studentID) => {
    try {
        const { data } = await $authHost.get('api/student/attendancestat/?studentID=' + studentID + '&begin=' + begin + '&end=' + end)
        return data
    } catch {
        return null
    }
}

export const fetchStudentStat = async (studentID, begin, end, link) => {
    try {
        const { data } = await $authHost.get('api/student/stat/?studentID=' + studentID + '&begin=' + begin + '&end=' + end + '&link=' + link)
        return data
    } catch {
        return null;
    }
}

export const createPlan = async (begin, end) => {
    const { data } = await $authHost.post('api/student/plan', { begin, end })
    return data
}

export const deletePlan = async (planID) => {
    const { data } = await $authHost.delete('api/student/plan/' + planID)
    return data
}

export const modyfyPlan = async (begin, name, planID, end) => {
    const { data } = await $authHost.put('api/student/plan', { begin, end, name, planID })
    return data
}

export const fetchPlans = async () => {
    const { data } = await $authHost.get('api/student/plans')
    return data
}

export const fetchTacts = async () => {
    try {
        const { data } = await $authHost.get('api/student/tacts')
        return data
    } catch (e) {
        message.error("Ошибка получения тактов!", e?.name, e?.message);
        return null
    }
} 

export const getPermissionCreateTact = async () => {
    const { data } = await $authHost.get('api/student/getPermissionCreateTact')
    return data
}


