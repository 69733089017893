import React, { useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container } from 'react-bootstrap';
import { fetchTeacherClassesSubjects } from '../../../http/teacherApi';
import { Context } from '../../..';

const TeacherClasses = observer(({nextID}) => {
    const {teacher} = useContext(Context)   
//    const [classesSubjects, setClassesSubjects] = useState([])

/*     useEffect(() => {
        loadData()
    }, [teacher.setSelectedSubMenu, teacher.selectedSideMenu]) */
    
    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        teacher.setSelectedModule({})
        teacher.setSelectedTheme({})
        const data = await fetchTeacherClassesSubjects()
       // setClassesSubjects(data)
        teacher.setClassesSubjects(data)
    }

    const openClasub = (clasub) => {
        teacher.setSelectedClasSubject(clasub)
        teacher.setSelectedSubMenu({id: nextID})
       /*  teacher.setSelectedSideMenu({})  */
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Класс/Предмет</th>
                    </tr>
                </thead>
                <tbody>
                    {teacher.classesSubjects.map(clasub =>
                        <tr key={clasub.classID +'|'+ clasub.subjectID}>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => openClasub(clasub)}
                            >   
                                {nextID === 41 ?
                                    clasub.className + ' / ' + clasub.subjectName + ' (' + clasub.unscoresCount  + ')'
                                    :
                                    clasub.className + ' / ' + clasub.subjectName
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default TeacherClasses;