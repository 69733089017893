import Modal from "react-bootstrap/Modal";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Button, FormControl } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { BiDotsHorizontal } from 'react-icons/bi';
import { createRoute, fetchRoutes, modyfyRoute, oneTheme } from '../../../http/teacherApi';
import DescriptionsTable from './DescriptionsTable'
import { Typography } from 'antd';

const { Paragraph } = Typography;

const RoutesTable = observer(({ show, onHide, themeID }) => {
  const [routes, setRoutes] = useState([])
  const [theme, setTheme] = useState({})
  const [descriptionsVisible, setDescriptionsVisible] = useState(false)
  const [routeIDToModal, setRouteIDToModal] = useState(0)

  useEffect(() => {
    loadData(themeID)
    oneTheme(themeID).then(data => setTheme(data))
  }, [themeID])

  const addRoute = async () => {
    const id = await createRoute(themeID)
    setRoutes([...routes, { name: '', id: id }])
  }

  const loadData = async () => {
    const data = await fetchRoutes(themeID)
    setRoutes(data)
  }

  const changeIndex = (index, id) => {
    changeRoute(index, null, null, id)
    setRoutes(routes.map(i => i.id === id ? { ...i, ['index']: index } : i))
  }

  const changeName = (name, id) => {
    changeRoute(null, name, null, id)
    setRoutes(routes.map(i => i.id === id ? { ...i, ['name']: name } : i))
  }

  const changeColor = (color, id) => {
    changeRoute(null, null, color, id)
    setRoutes(routes.map(i => i.id === id ? { ...i, ['color']: color } : i))
  }

  const changeRoute = async (index, name, color, id) => {
    const data = await modyfyRoute(id, index, name, color)
  }

  const openDescriptions = (id) => {
    setRouteIDToModal(id)
    setDescriptionsVisible(true)
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Маршруты для темы #{theme.index} ({theme.name})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Название</th>
              <th>Цвет</th>
              <th><Button onClick={() => addRoute()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
            </tr>
          </thead>
          <tbody>
            {routes.map(route =>
              <tr key={route.id}>
                <td>
                  {<Paragraph
                    editable={{
                      tooltip: 'Номер',
                      onChange: (value) => changeIndex(value, route.id),
                      triggerType: ['icon', 'text'],
                    }}
                  >
                    {route.index}
                  </Paragraph>}
                  {/*        <FormControl
                    placeholder='Номер'
                    value={route.index}
                    onChange={(e) => changeIndex(e.target.value, route.id)}
                  /> */}
                </td>
                <td>
                <Paragraph
                    editable={{
                      tooltip: 'Введите название',
                      onChange: (value) => changeName(value, route.id),
                      triggerType: ['icon', 'text'],
                    }}
                  >
                    {route.name}
                  </Paragraph>
         {/*          <FormControl
                    placeholder='Введите название'
                    value={route.name}
                    onChange={(e) => changeName(e.target.value, route.id)}
                  /> */}
                </td>
                <td>
                  <FormControl
                    placeholder='Введите цвет'
                    value={route.color}
                    onChange={(e) => changeColor(e.target.value, route.id)}
                    type="color"
                  />
                </td>
                <td><Button onClick={() => openDescriptions(route.id)}><BiDotsHorizontal className="d-flex justify-content-between" /></Button></td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
      </Modal.Footer>
      <DescriptionsTable show={descriptionsVisible} onHide={() => setDescriptionsVisible(false)} routeID={routeIDToModal} />
    </Modal>
  );
});

export default RoutesTable;