import Modal from "react-bootstrap/Modal";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Button, FormControl, Dropdown } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { BiDotsHorizontal } from 'react-icons/bi';
import { createTheme, fetchThemes, modyfyTheme, oneModule } from '../../../http/teacherApi';
import { fetchWorkTypes } from "../../../http/adminApi";
import RoutesTable from "./RoutesTable";
import { Typography } from 'antd';

const { Paragraph } = Typography;

const ThemesTable = observer(({ show, onHide, moduleID }) => {
  const [themes, setThemes] = useState([])
  const [module, setModule] = useState({})
  const [workTypes, setWorkTypes] = useState([])
  const [routesVisible, setRoutesVisible] = useState(false)
  const [themeIDToModal, setThemeIDToModal] = useState(0)

  useEffect(() => {
    fetchWorkTypes().then(data => setWorkTypes(data))
  }, [])

  useEffect(() => {
    loadData(moduleID)
    oneModule(moduleID).then(data => setModule(data))
  }, [moduleID])

  const addTheme = async () => {
    const id = await createTheme(moduleID)
    setThemes([...themes, { name: '', id: id }])
  }

  const loadData = async () => {
    const data = await fetchThemes(moduleID)
    setThemes(data)
  }

  const changeIndex = (index, id) => {
    changeTheme(index, null, null, id, null)
    setThemes(themes.map(i => i.id === id ? { ...i, ['index']: index } : i))
  }

  const changeName = (name, id) => {
    changeTheme(null, name, null, id, null)
    setThemes(themes.map(i => i.id === id ? { ...i, ['name']: name } : i))
  }

  const changeWorkType = (workTypeId, id) => {
    changeTheme(null, null, workTypeId, id, null)
    setThemes(themes.map(i => i.id === id ? { ...i, ['workTypeId']: workTypeId } : i))
  }

  const changeDate = (end, id) => {
    changeTheme(null, null, null, id, end)
    setThemes(themes.map(i => i.id === id ? { ...i, ['end']: end } : i))
  }

  const changeTheme = async (index, name, workType, id, end) => {
    const data = await modyfyTheme(id, index, name, workType, end)
  }

  const openRoutes = (id) => {
    setThemeIDToModal(id)
    setRoutesVisible(true)
  }

  return (
    <Modal
      /*       onShow={setInputs} */
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Темы для модуля #{module.index} ({module.name})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Название</th>
              <th>Тип контроля</th>
              <th>Дата завершения</th>
              <th><Button onClick={() => addTheme()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
            </tr>
          </thead>
          <tbody>
            {themes.map(theme =>
              <tr key={theme.id}>
                <td>
                  {<Paragraph
                    editable={{
                      tooltip: 'Номер',
                      onChange: (value) => changeIndex(value, theme.id),
                      triggerType: ['icon', 'text'],
                    }}
                  >
                    {theme.index}
                  </Paragraph>}
         {/*          <FormControl
                    placeholder='Номер'
                    value={theme.index}
                    onChange={(e) => changeIndex(e.target.value, theme.id)}
                  /> */}
                </td>
                <td>
                  <Paragraph
                    editable={{
                      tooltip: 'Введите название',
                      onChange: (value) => changeName(value, theme.id),
                      triggerType: ['icon', 'text'],
                    }}
                  >
                    {theme.name}
                  </Paragraph>
                  {/*         <FormControl
                    placeholder='Введите название'
                    value={theme.name}
                    onChange={(e) => changeName(e.target.value, theme.id)}
                  /> */}
                </td>
                <td>
                  <Dropdown className="mt-2 mb-2">
                    <Dropdown.Toggle>{workTypes.filter(workType => workType.id == theme.workTypeId)[0]?.name || "Выберите тип контроля"}</Dropdown.Toggle>
                    <Dropdown.Menu>
                      {workTypes.map(workType =>
                        <Dropdown.Item
                          onClick={() => changeWorkType(workType.id, theme.id)}
                          key={"dr" + workType.id + theme.id}
                        >
                          {workType.name}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td>
                  <FormControl
                    placeholder='Введите дату'
                    value={theme?.end?.split('T')[0]}
                    onChange={(e) => changeDate(e.target.value, theme.id)}
                    type='date'
                  />
                </td>
                <td><Button onClick={() => openRoutes(theme.id)}><BiDotsHorizontal className="d-flex justify-content-between" /></Button></td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
      </Modal.Footer>
      <RoutesTable show={routesVisible} onHide={() => setRoutesVisible(false)} themeID={themeIDToModal} />
    </Modal>
  );
});

export default ThemesTable;