import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container, Form, FormControl } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createLessonType, modyfyLessonType, deleteLessonType, fetchClasses, fetchSchedule, modyfySchedule } from '../../http/adminApi';
import { fetchTeacherSchedule } from '../../http/teacherApi';
import HTMLViewer from '../utilites/HTMLViewer';

//Расписание уроков
const TeacherSheduleOld = observer(() => {
   // const { teacher } = useContext(Context)
    const { admin } = useContext(Context)
    const [shedule, setSchedule] = useState([])

    const days = [
        { day: 1 }, { day: 2 }, { day: 3 }, { day: 4 }, { day: 5 },
    ]

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const data = await fetchTeacherSchedule()
        setSchedule(data)
        //fetchTeacherSchedule().then(data => teacher.setTeacherSchedule(data))
    }

    function getTeacherScheduleName(day, intID) {
        let ret = ""
        const classSub = shedule?.filter(teacherSchedule => (teacherSchedule.day === day && teacherSchedule.intID === intID))[0]
        //const classSub = teacher.teacherSchedule?.filter(teacherSchedule => (teacherSchedule.day === day && teacherSchedule.intID === intID))[0]
        if (classSub) {
            ret = "<div><b>" + classSub.subject.name + "</b></div>"
            classSub.classes.forEach(clas => {
                ret = ret + "<div>" + clas.name + "</div>"
            });
        }
        return ret
        //return teacher.teacherSchedule?.filter(teacherSchedule => (teacherSchedule.day === day && teacherSchedule.intID === intID))[0]?.classubjectName
    }

    function currentDay(day) {
        var d = new Date();
        var n = d.getDay();
        if (n === day) {
            return true
        } else {
            return false
        }
    }

    function currentTime(time) {
        var ret = false
        var now = new Date();
        var begin = new Date();
        var end = new Date();
        var h1 = time.split(" - ")[0].split(':')[0]
        var m1 = time.split(" - ")[0].split(':')[1]
        var h2 = time.split(" - ")[1].split(':')[0]
        var m2 = time.split(" - ")[1].split(':')[1]
        begin.setHours(h1, m1)
        end.setHours(h2, m2)
        if (begin <= now && now < end) {
            ret = true
        }
        return ret
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Время</th>
                        <th>Понедельник</th>
                        <th>Вторник</th>
                        <th>Среда</th>
                        <th>Четверг</th>
                        <th>Пятница</th>
                    </tr>
                </thead>
                <tbody>
                    {console.log("SH", admin.lessonsInterval)}
                    {admin.lessonsInterval.map(lessonsInterval =>
                        <tr key={'tr' + lessonsInterval.id}
                            style={currentTime(lessonsInterval.name) ? { backgroundColor: 'hsl(47, 100%, 95%)' } : {}}
                        >
                            <td>
                                {lessonsInterval.name}
                            </td>
                            {days.map(day =>
                                <td key={'td' + day.day + lessonsInterval.id} 
                                    style={currentDay(day.day) ? {backgroundColor: 'hsl(47, 100%, 95%)', textAlign: 'center'} : { textAlign: 'center' }}
                                >
                                    <HTMLViewer
                                        value={getTeacherScheduleName(day.day, lessonsInterval.id)}
                                    ></HTMLViewer>
                                    {/* {getTeacherScheduleName(day.day, lessonsInterval.id) || ""} */}
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default TeacherSheduleOld;