import React, { useContext, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Button, Container, FormControl } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { BiDotsHorizontal } from 'react-icons/bi';
import { createModule, deleteModule, fetchModules, modyfyModule } from '../../../http/teacherApi';
import { Alert, message, Popconfirm, Typography } from 'antd';
import { Context } from '../../..';

const { Paragraph } = Typography;

const ModulesList = observer((data) => {
    const { teacher } = useContext(Context)
    const { admin } = useContext(Context)

    useEffect(() => {
        console.log("ML", data);
        loadData(data.classID, data.subjectID)
    }, [data])

    const addModule = async () => {
        const id = await createModule(data.classID, data.subjectID)
        teacher.setTeacherModules([...teacher.teacherModules, { name: '', id: id }])
    }

    const loadData = async (classID, subjectID) => {
        const data = await fetchModules(classID, subjectID)
        teacher.setTeacherModules(data)
    }

    const changeIndex = (index, id) => {
        changeModule(index, null, null, null, id)
        teacher.setTeacherModules(teacher.teacherModules.map(i => i.id === id ? { ...i, ['index']: index } : i))
    }

    const changeName = (name, id) => {
        changeModule(null, name, null, null, id)
        teacher.setTeacherModules(teacher.teacherModules.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }

    const changeBegin = (begin, id) => {
        changeModule(null, null, begin, null, id)
        teacher.setTeacherModules(teacher.teacherModules.map(i => i.id === id ? { ...i, ['begin']: begin } : i))
    }

    const changeEnd = (end, id) => {
        changeModule(null, null, null, end, id)
        teacher.setTeacherModules(teacher.teacherModules.map(i => i.id === id ? { ...i, ['end']: end } : i))
    }

    const changeModule = async (index, name, begin, end, id) => {
        const data = await modyfyModule(id, index, name, begin, end)
    }

    const delModule = async (module) => {
        console.log("MOD", module);
        const data = await deleteModule(module.id)
        if (data > 0) {
            message.success("Удаление успешно!")
            //TODO перезагрузка данных
        } else {
            message.error("Удаление не удалось!")
        }
        loadData(data.classID, data.subjectID)
    }

    const openThemes = (module) => {
        teacher.setSelectedModule(module)
        teacher.setSelectedSubMenu({ id: 61 })
        admin.setSelectedSubMenu({ id: 141 })
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Модуль</th>
                        <th>Начало</th>
                        <th>Конец</th>
                        <th></th>
                        <th><Button onClick={() => addModule()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {teacher.teacherModules.map(module =>
                        <tr key={module.id}>
                            <td>
                                {<Paragraph
                                    editable={{
                                        tooltip: 'Номер',
                                        onChange: (value) => changeIndex(value, module.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {module.index}
                                </Paragraph>}
                                {/*       <FormControl
                                    placeholder='Номер'
                                    value={module.index}
                                    onChange={(e) => changeIndex(e.target.value, module.id)}
                                /> */}
                            </td>
                            <td>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Введите название',
                                        onChange: (value) => changeName(value, module.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {module.name}
                                </Paragraph>
                                {/*   <FormControl
                                    placeholder='Введите название'
                                    value={module.name}
                                    onChange={(e) => changeName(e.target.value, module.id)}
                                /> */}
                            </td>
                            <td>
                                <FormControl
                                    placeholder='Введите начало'
                                    value={module?.begin ? module.begin.split("T")[0] : ''}
                                    onChange={(e) => changeBegin(e.target.value, module.id)}
                                    type='date'
                                />
                            </td>
                            <td>
                                <FormControl
                                    placeholder='Введите окончание'
                                    value={module?.end ? module.end.split("T")[0] : ''}
                                    onChange={(e) => changeEnd(e.target.value, module.id)}
                                    type='date'
                                />
                            </td>
                            {/*  <td><Button onClick={() => openThemes(module)}><BiDotsHorizontal className="d-flex justify-content-between" /></Button></td> */}
                            <td>
                                <Button onClick={() => openThemes(module)}>
                                    <BiDotsHorizontal className="d-flex justify-content-between" />
                                </Button>
                            </td>
                            <td>
                                <Popconfirm
                                    title={'Вы уверены что хотите удалить ' + module.name + '?'}
                                    onConfirm={() => delModule(module)}
                                    onCancel={() => message.warning('Удаление отменено')}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Button>
                                        <FaMinus className="d-flex justify-content-between" />
                                    </Button>
                                </Popconfirm>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default ModulesList;